* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main-color {
  background: rgb(117, 137, 251);
}

.grey-color {
  background: rgb(236, 236, 236);
}

.heading-title {
  padding-top: 70px;
  margin-bottom: 35px;
  color: rgb(82, 82, 82);
}
