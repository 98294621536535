@media only screen and (max-width: 1185px) {
  .project-card {
    width: 300px;
    margin: 20px;
    text-align: center;
    background: rgb(236, 236, 236);
    transition: 1s;
  }

  .project-card a {
    text-decoration: none;
    color: rgb(51, 51, 51);
    transition: 1s;
  }

  .project-card:hover {
    background: rgb(85, 102, 255);
    transition: 1s;
    box-shadow: 10px 10px 14px rgba(128, 128, 128, 0.466);
    cursor: pointer;
  }

  .project-card img {
    object-fit: contain;
    width: 301px;
  }

  .project-card h1 {
    font-size: 16px;
    padding: 10px;
  }
}

/* Desktop and Laptops */
@media only screen and (min-width: 1186px) {
  .project-card {
    width: 300px;
    margin: 20px;
    text-align: center;
    background: rgb(236, 236, 236);
    transition: 1s;
  }

  .project-card a {
    text-decoration: none;
    color: rgb(51, 51, 51);
    transition: 1s;
  }

  .project-card:hover {
    background: rgb(117, 137, 251);
    transition: 1s;
    box-shadow: 10px 10px 20px rgba(128, 128, 128, 0.644);
    cursor: pointer;
  }

  .project-card:hover > a {
    color: white;
    transition: 1s;
  }

  .project-card img {
    object-fit: contain;
    width: 300px;
  }

  .project-card h1 {
    font-size: 16px;
    padding: 10px;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
