@media only screen and (max-width: 1185px) {
  .flex-container {
    display: flex;
    flex-direction: column;
  }

  .Heading h1 {
    font-size: 30px;
    font-weight: normal;
    padding-top: 20px;
    padding-bottom: 40px;
    margin-bottom: 5px;
  }

  .name {
    border-bottom: 3px solid rgb(117, 137, 251);
  }

  .Heading p {
    margin: 0 auto;
    width: 95%;
    line-height: 2.5;
  }

  .heading-words {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
  }

  .heading-words button {
    margin-top: 30px;
    background: rgb(85, 102, 255);
    width: 170px;
    height: 50px;
    font-size: 20px;
    color: #fff;
    border-radius: 25px;
  }

  .heading-words button a {
    color: #fff;
    text-decoration: none;
  }
  .heading-words button:hover {
    animation: flashingLights 0.9s infinite ease-in-out;
    font-size: 22px;
    width: 190px;
    height: 70px;
    transition: 0.2s ease-in;
    cursor: pointer;
  }

  .heading-image {
    /* display: flex;
    justify-content: center;
    align-items: center; */
    /* border: 2px solid; */
    margin-bottom: 20px;
    padding: 20px;
    background: rgb(85, 102, 255);
    box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.178);
    z-index: -1;
    color: #fff;
  }

  .heading-image div h3 {
    margin-bottom: 30px;
    padding-left: 34px;
    text-align: center;
  }

  .heading-image div p {
    font-size: 14px;
  }

  .heading-image img {
    object-fit: contain;
    border-radius: 100px;
    width: 120px;
    /* margin-bottom: 20px; */
    margin-right: 10px;
  }
}

/* Desktop and Laptops */
@media only screen and (min-width: 1186px) {
  .Heading {
    height: 100vh;
    padding-left: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(82, 82, 82);
    /* border: 2px solid; */
  }
  .name {
    border-bottom: 3px solid rgb(117, 137, 251);
  }

  .Heading h1 {
    font-size: 60px;
    font-weight: normal;
    padding-bottom: 40px;
    margin-bottom: 5px;
  }

  .Heading p {
    width: 90%;
    line-height: 2.5;
  }

  .heading-words {
    width: 700px;
    /* border: 2px solid black; */
  }

  .heading-words button {
    margin-top: 30px;
    margin-left: 30px;
    background: rgb(85, 102, 255);
    width: 170px;
    height: 50px;
    font-size: 20px;
    color: #fff;
    border-radius: 25px;
    border: none;
    transition: 0.2s ease-in;
  }

  .heading-words button a {
    color: #fff;
    text-decoration: none;
  }
  .heading-words button:hover {
    animation: flashingLights 0.9s infinite ease-in-out;
    font-size: 22px;
    width: 190px;
    height: 70px;
    box-shadow: -2px 5px 10px rgb(204, 201, 201);
    transition: 0.2s ease-in;
    cursor: pointer;
  }

  @keyframes flashingLights {
    0% {
      /* box-shadow: -2px 5px 10px rgb(255, 255, 255); */
      border: 2px solid rgb(255, 255, 255);
    }

    100% {
      border: 2px solid rgb(236, 236, 236);
    }
  }
  .heading-image {
    width: 300px;
    height: 500px;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* border: 2px solid; */
  }

  .heading-image div {
    width: 204px;
    /* background: #fff; */
  }
  .heading-image div h3 {
    margin-bottom: 30px;
    text-align: center;
  }

  .heading-image div p {
    font-size: 14px;
  }

  .heading-image img {
    object-fit: contain;
    border-radius: 100px;
    width: 200px;
    margin-bottom: 20px;
  }
}
