@media only screen and (max-width: 1185px) {
  .Footer-container {
    height: 15vh;
    background: rgb(236, 236, 236);
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Center-text-container {
    position: relative;
    text-align: center;
    height: 20px;
  }
}

/* Desktop and Laptops */
@media only screen and (min-width: 1186px) {
  .Footer-container {
    height: 15vh;
    background: rgb(236, 236, 236);
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Center-text-container {
    position: relative;
    text-align: center;
    height: 20px;
  }
}
