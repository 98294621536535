@media only screen and (max-width: 1185px) {
  .Spa-Container {
    width: 95vw;
    padding-top: 9vh;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 1186px) {
  .Spa-Container {
    width: 80vw;
    float: right;
    overflow-y: auto;
    scroll-behavior: smooth;
  }
}
