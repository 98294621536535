@media only screen and (max-width: 1185px) {
  .Menu-Container {
    position: fixed;
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
  }
  .Menu-Container ul {
    display: none;
  }

  .Menu-Container ul:nth-of-type(2) li img {
    object-fit: contain;
    width: 40px;
  }

  .Menu-Container ul:nth-of-type(2) {
    list-style-type: none;
    width: 100vw;
    height: 6vh;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    margin-left: 7px;
  }

  .Menu-Container ul li {
    /* border: 2px solid; */
  }

  .Menu-Container ul li a {
    text-decoration: none;
    /* color: #fff; */
    font-size: 20px;
    padding: 20px;
  }

  .show {
    position: fixed;
    top: 6vh;
    width: 100vw;
    background: #fff;
    height: 100vh;
  }

  .show ul {
    /* border: 2px solid black; */
    color: black;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style-type: none;
  }
  .show ul li {
    margin: 20px;
    /* border: 1px solid black; */
  }
  .show ul li a {
    text-decoration: none;
    color: black;
    font-size: 25px;
  }

  .hide {
    display: none;
  }
}

/* Desktop and Laptops */
@media only screen and (min-width: 1186px) {
  .Menu-Container {
    position: fixed;
    width: 20vw;
    height: 100vh;
    float: left;
  }

  .Menu-Container ul {
    list-style-type: none;

    width: 20vw;
    height: 100vh;
    padding-top: 90px;
  }

  .Menu-Container ul li {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Menu-Container ul li a {
    text-decoration: none;
    color: #fff;
    font-size: 20px;
    padding: 20px;
  }

  .hide {
    display: none;
  }
}
