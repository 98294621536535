@media only screen and (max-width: 1185px) {
  .Blogs {
    /* height: 500px; */
    background: rgb(236, 236, 236);
    text-align: center;
    padding-bottom: 50px;
  }

  .Blogs h1 {
    color: rgb(82, 82, 82);
    padding-top: 60px;
    padding-bottom: 40px;
  }
  .Blogs-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .Blog-post {
    /* border: 2px solid; */
    width: 325px;
    margin: 20px;
    /* opacity: 0.5; */
    box-shadow: 5px 20px 15px rgba(128, 128, 128, 0.356);
  }

  .Blog-post:hover {
    opacity: 1;
    transition: 1s;
    cursor: pointer;
  }

  .Blog-post img {
    object-fit: contain;
    width: 325px;
  }
}

/* Desktop and Laptops */
@media only screen and (min-width: 1186px) {
  .Blogs {
    height: 100vh;
    background: rgb(236, 236, 236);
    text-align: center;
    padding-bottom: 30px;
  }

  .Blogs-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .Blog-post {
    width: 325px;
    margin: 20px;
    opacity: 0.5;
    box-shadow: 5px 20px 15px rgba(128, 128, 128, 0.356);
  }

  .Blog-post:hover {
    opacity: 1;
    transition: 1s;
    cursor: pointer;
  }

  .Blog-post img {
    object-fit: contain;
    width: 325px;
  }
}
