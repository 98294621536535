@media only screen and (max-width: 1185px) {
  .Skills {
    overflow: scroll;
    text-align: center;
    padding-bottom: 20px;
  }

  .skills-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .skill-card {
    margin:20px;
  }

  .skill-card img {
    object-fit: contain;
    width: 100px;
    height: 100px;
  }
}

/* Desktop and Laptops */
@media only screen and (min-width: 1186px) {
  .Skills {
    height: 100vh;
    text-align: center;
    /* border: 2px solid black; */
  }

  .skills-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    /* border: 2px solid black; */
    height: 70%;
  }

  .skill-card {
    width: 200px;
  }
}
