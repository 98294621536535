@media only screen and (max-width: 1185px) {
  .Contact-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 900px;
    /* padding-top: 10px; */
    /* border: 2px solid black; */
  }

  .Contact-container form {
    /* border: 2px solid; */
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgb(236, 236, 236);
    border-radius: 4px;
    padding: 30px;
    box-shadow: 10px 10px 25px rgba(165, 165, 165, 0.329);
  }

  .Contact-container form input[type='text'] {
    height: 30px;
    width: 250px;
    border-radius: 15px;
    border: none;
    padding: 20px;
    font-size: 16px;
    text-align: center;
  }

  .Contact-container form textarea {
    height: 150px;
    width: 70vw;
    max-width: 500px;
    margin-bottom: 30px;
    border-radius: 15px;
    border: none;
    font-size: 16px;
    padding: 20px;
  }

  .Contact-container form label {
    margin: 10px;
    color: rgb(82, 82, 82);
  }

  .Contact-container form button:hover {
    cursor: pointer;
  }

  .Contact-container form a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 130px;
    height: 45px;
    background: rgb(250, 250, 250);
    border: none;
    border-radius: 5px;
    color: rgb(51, 51, 51);
    animation: flash 3s infinite ease-in-out;
    font-size: 18px;
    font-weight: bold;
  }

  .Contact-container form a {
    text-decoration: none;
    color: #000;
  }

  .Contact-section {
    width: 100%;
    text-align: center;
    font-size: 26px;
    padding: 10px;
    padding-bottom: 50px;
    color: rgb(82, 82, 82);
  }

  @keyframes flash {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

/* Desktop and Laptops */
@media only screen and (min-width: 1186px) {
  .Contact-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 76vh;
  }

  .Contact-container form {
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgb(236, 236, 236);
    border-radius: 4px;
    padding: 30px;
    box-shadow: 10px 10px 25px rgba(165, 165, 165, 0.329);
  }

  .Contact-container,
  .Contact-section {
    margin: 50px;
  }

  .Contact-container form input[type='text'] {
    height: 30px;
    width: 250px;
    border-radius: 15px;
    border: none;
    padding: 20px;
    font-size: 16px;
    text-align: center;
  }

  .Contact-container form input[type='text']:focus,
  .Contact-container form textarea:focus {
    outline: none;
  }

  .Contact-container form textarea {
    height: 150px;
    width: 350px;
    margin-bottom: 30px;
    border-radius: 15px;
    border: none;
    font-size: 16px;
    padding: 20px;
  }

  .Contact-container form label {
    margin: 10px;
    color: rgb(82, 82, 82);
    font-size: 20px;
    font-weight: bold;
  }

  .Contact-container form a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 130px;
    height: 45px;
    background: rgb(250, 250, 250);
    border: none;
    border-radius: 5px;
    color: rgb(51, 51, 51);
    animation: flash 3s infinite ease-in-out;
    font-size: 18px;
    font-weight: bold;
  }

  .Contact-container form a {
    text-decoration: none;
    color: #000;
  }

  .Contact-section {
    font-size: 16px;
    color: rgb(82, 82, 82);
  }

  .Contact-section h1 {
    font-size: 60px;
    line-height: 1.5;
  }

  .Contact-section p {
    font-size: 18px;
    line-height: 2;
  }

  @keyframes flash {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
