@media only screen and (max-width: 1185px) {
  .Project-Container {
    text-align: center;
    padding-bottom: 70px;
    background: rgb(245, 245, 245);
  }

  .section-name {
    margin-bottom: 20px;
    color: rgb(82, 82, 82);
  }

  .filter-tab {
    /* border: 2px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    border-bottom: 1px solid rgba(199, 199, 199, 0.507);
    box-shadow: 0px 5px 3px rgba(0, 0, 0, 0.027);
  }

  .filter-tab button {
    margin-bottom: 20px;
    border: none;
    background: none;
    font-size: 18px;
    color: rgb(82, 82, 82);
    transition: 1s;
    outline: none;
    text-decoration: underline;
    width: 33.3%;
  }

  .Project-Grid {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
}

/* Desktop and Laptops */
@media only screen and (min-width: 1186px) {
  .Project-Container {
    height: 100vh;
    text-align: center;
    padding-bottom: 70px;
    background: rgb(245, 245, 245);
    /* border: 2px solid black; */
  }

  .section-name {
    margin-bottom: 20px;
    color: rgb(82, 82, 82);
  }

  .Project-Grid {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    overflow-y: scroll;
    height: 75%;
    animation: fade 1s;
  }

  .filter-tab {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    border-bottom: 1px solid rgba(199, 199, 199, 0.507);
    box-shadow: 0px 5px 3px rgba(0, 0, 0, 0.027);
  }

  .filter-tab button {
    margin: 40px;
    margin-bottom: 20px;
    border: none;
    background: none;
    font-size: 18px;
    color: rgb(82, 82, 82);
    transition: 1s;
    outline: none;
  }

  .filter-tab button:hover {
    cursor: pointer;
    text-decoration: underline;
    color: rgb(15, 15, 15);
    transition: 1s;
  }

  @keyframes fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
